<template>
<div>
  <v-dialog v-model="dialogDelete" max-width="380px"  transition="dialog-top-transition">
    <v-card class="pa-7">
      <v-card-title class="d-flex justify-center align-center">
        <v-row  dense>
          <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background-alert">
                  <v-icon dense color="main_red" size="30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
          </v-col>
          <v-col cols="12">
            <span>{{ $t('deleteQuestion') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
      <v-card-actions class="mb-2">
        <v-row dense>
          <v-col cols="12">
            <v-btn block depressed color="red" class="text-color-white"  @click="deleteUserConfirm">{{ $t('deleteField') }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn outlined depressed color="black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-data-table
      dense
      :items="filteredBillings"
      :headers="headers"
      :height="pageHeight(150)"
      class="pa-1 pointer"
      :loading="loading"
      :headers-length="headers.length"
      :no-data-text="$t('no-data-available')"
      :loading-text="$t('loading')"
      sort-by="id"
      item-key="id"
      fixed-header
      :footer-props="footerProps"
      :items-per-page="-1"
  >
    <template v-slot:item="{ item }">
      <tr >
        <td class="text-left font-weight-medium font-size12-5 ">
          {{ item.userFullname }}
        </td>
        <td class="text-left font-weight-medium font-size12-5 ">
          {{ (item.roleId === 4) ? $t('nurse') : ((item.roleId === 7) ? $t('caregiver') : $t('doctor')) }}
        </td>
        <td class="text-left font-weight-medium font-size12-5 ">
          {{ item.officeName }}
        </td>
<!--        <td class="text-left font-size12">-->
<!--          {{ item.organizationName }}-->
<!--        </td>-->
        <td>
          <div class="d-flex align-center justify-end">
            <v-menu
                left
                bottom
                class="py-0"
                offset-y
                transition="slide-x-transition"
            >
              <template v-slot:activator="{on, attrs}">
                <v-btn
                    small
                    icon
                    color="black"
                    v-bind="attrs"
                    v-on="on"
                >
                  <span class="mdi mdi-18px mdi-dots-horizontal "></span>
                </v-btn>
              </template >

              <v-list class="px-0" dense >
                <v-list-item class="py-0 my-0" link dense @click="handleActionEdit(item)">
                  <v-list-item-icon class="mx-1 ">
                    <v-icon class="icon-img mdi-18px" color="black">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="font-bold font-size14">{{$t("edit")}}</v-list-item-content>
                </v-list-item>
                <v-divider/>
                <v-list-item v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER' || roleName === 'ORGANIZATION_ADMIN'" link dense @click="handleActionDelete(item)">
                  <v-list-item-icon class="mx-1 ">
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="font-bold font-size14" >{{ $t('delete') }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  data () {
    return {
      dialogDelete: false,
      selectedItem: {},
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      seach: null,
      loading: false,
      headers: [
        { text: this.$t('name'), sortable: false, class: 'secondary', align: 'start' },
        { text: this.$t('role'), sortable: false, class: 'secondary' },
        { text: this.$t('Office'), sortable: false, class: 'secondary' },
        { text: this.$t('actions'), sortable: false, class: 'secondary', align: 'end' },
        // { text: this.$t('Organization'), sortable: false, class: 'secondary' },
      ],
    };
  },
  computed: {
    ...mapState({
      billingTemplatesAll: (state) => state.usersbillingsauthorization.billingTemplatesAll,
    }),
    ...mapGetters({
      appBarSearch: 'filterbar/getSearch',
      roleName: 'authentication/getRole',
      pageHeight: 'pageHeight',
    }),
    filteredBillings () {
      const searchTerm = this.appBarSearch ? this.appBarSearch.toLowerCase() : '';
      return this.billingTemplatesAll.filter(item => {
        const username = item.userFullname && item.userFullname ? item.userFullname.toLowerCase() : '';
        return username.includes(searchTerm);
      });
    },
  },
  methods: {
    handleActionEdit (item) {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', item);
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'user billings',
      });
      this.$store.dispatch('usersbillingsauthorization/getusersbillingsauthorizationbyid', item.id);
    },
    async handleActionDelete (item) {
      this.dialogDelete = true;
      this.selectedItem = item;
    },
    async deleteUserConfirm () {
      await this.$store.dispatch('usersbillingsauthorization/deletebillingusersauthorization', this.selectedItem.id)
          .then(res => {
            if (res) {
              showSuccessAlert(this.$t('success-operation'));
            } else {
              showErrorAlert(this.$t('failed-operation'));
            }
            this.billinguserauthorization();
            this.closeDeleteDialog();
          });
    },
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.selectedItem = {};
    },
    billinguserauthorization () {
      this.loading = true;
      this.$store.dispatch('usersbillingsauthorization/billinguserauthorizationV1').then(res => {
      });
      this.loading = false;
    },
  },
  mounted () {
    this.billinguserauthorization();
  },
};
</script>
<style>

</style>
